<template>
  <v-dialog
    v-model="value"
    persistent
    max-width="60%"
  >
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <v-col>
            <span class="headline">Fiware Entities</span>
          </v-col>
          <v-col class="text-right">
            <v-btn
              x-small
              icon
              @click="isJson = !isJson"
            >
              <v-icon>mdi-code-json</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <fiware-entities-form :isJSON="isJson" :entities.sync="entitiesForm" @valid="valid = $event" @action="actionEntitiesForm($event)" ref="validate"></fiware-entities-form>
        </v-container>
      </v-card-text>
      <!-- <v-card-text v-else>
        <v-container>
          <v-jsoneditor v-model="filterEntitiesForm" :options="{ mode: 'code' }" :plus="false" height="400px" @error="jsoneditorError($event)"></v-jsoneditor>
        </v-container>
      </v-card-text> -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="cancelEntities()"
        >
          Close
        </v-btn>
        <confirm-dialog v-model="isCancel" type="cancel" title="fiware entities" @confirm="confirmCancelEntities()"></confirm-dialog>
        <v-btn
          :disabled="!valid"
          color="blue darken-1"
          text
          @click="createEntities()"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import api from '@/services/api'

var attributesForm = {
  attrName: null,
  type: {
    items: ['Boolean', 'Number', 'Text', 'geo:json', 'geo:point'],
    type: null
  },
  attrValueType: 'single',
  values: {
    singleValue: null,
    objectValue: {
      objType: ['keyValue'],
      keyValue: [
        {
          key: null,
          value: null
        }
      ],
      objValue: [
        {
          objName: null,
          keyValue: [
            {
              key: null,
              value: null
            }
          ]
        }
      ],
      arrayValue: [
        {
          key: null,
          value: [],
          inputArr: null
        }
      ]
    },
    arrayValue: {
      arrtype: ['value'],
      singleValue: {
        values: [],
        inputValue: null
      },
      keyValue: [
        {
          key: null,
          value: null,
          inputValue: null
        }
      ],
      arrValue: {
        arr: [
          {
            values: [],
            inputValue: null
          }
        ]
      }
    }
  },
  metadata: [{
    name: null,
    value: null,
    type: null
  }]
}

Object.freeze(attributesForm)

export default {
  name: 'NewFiwareEntity',
  components: {
    ConfirmDialog: () => import('@/components/ConfirmDialog'),
    FiwareEntitiesForm: () => import('@/components/fiware/FiwareEntitiesForm')
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data: () => ({
    valid: true,
    isJson: false,
    entitiesForm: {
      id: null,
      type: null,
      attributes: [JSON.parse(JSON.stringify(attributesForm))]
    },
    isCancel: false,
    attrIndex: -1
  }),
  computed: {
    commonRules: function () {
      return [
        v => !!v || 'field is required',
        v => (v && v.length <= 50) || 'field must be less than 50 characters'
      ]
    },
    typeofNumberRules: function () {
      return [
        v => !!v || 'field is required',
        v => /^(-?\d+\.\d+)$|^(-?\d+)$/i.test(v) || 'field must be number'
      ]
    }
  },
  methods: {
    createEntities: function () {
      this.$refs.validate.createEntities()
    },
    cancelEntities: function () {
      if (this.entitiesForm.attributes.length > 0 || this.entitiesForm.id || this.entitiesForm.type) {
        this.isCancel = true
      } else {
        this.$emit('input', false)
      }
    },
    confirmCancelEntities: function () {
      this.$emit('input', false)
      if (!this.isJson) this.$refs.validate.resetValidate()
      this.entitiesForm = {
        id: null,
        type: null,
        attributes: [JSON.parse(JSON.stringify(attributesForm))]
      }
      this.isJson = false
      // this.valid = true
    },
    actionEntitiesForm: function (event) {
      this.$emit('input', false)
      this.$emit('fetch')
      this.entitiesForm = {
        id: null,
        type: null,
        attributes: [JSON.parse(JSON.stringify(attributesForm))]
      }
      this.$refs.validate.resetValidate()
    },
    jsoneditorError: function (err) {
      console.error(err)
    }
  },
  created () {
  }
}
</script>
